import React from 'react'
import SELogo from '../lib/assets/sharpend-logo.png'
import NCPHLogo from '../lib/assets/nirvanacph-logo.png'
import '../lib/scss/footer.scss'

const Footer = () => {

    return (
      <footer className="footer">

        <p className="footer__copyright">
          Prototype. Pilot. Scale.
        </p>

        <div className="footer__logos">
          <div className="footer__logos__left"><img width="300" src={NCPHLogo} alt="Nirvana CPH Logo" /></div>
          <div className="footer__logos__right"><img width="300" src={SELogo} alt="SharpEnd Logo" /></div>
        </div>

      </footer>
    )
}

export default Footer
