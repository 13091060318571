import React from 'react';
import Logo from '../lib/assets/logo.svg'
import '../lib/scss/header.scss'

const Header = ({shouldMakeHeaderSolid}) => {
  if(shouldMakeHeaderSolid) {
    return (
      <header className="AppHeader solid">
        <a href="/"><img src={Logo} alt="logo" /></a>
      </header>
    );
  } else {
  return (
    <header className="AppHeader">
      <a href="/"><img src={Logo} alt="logo" /></a>
    </header>
  );
}
}

export default Header;
