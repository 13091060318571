import React, {useState} from 'react';
import '@lottiefiles/lottie-player';
import '../lib/scss/intro.scss';
import arrow from '../lib/assets/arrow2.svg'
import CardActivation from '../components/card-activation';

const IntroView = ({showExamples, exploreNow}) => {

  const [showActivation, setShowActivation] = useState(true);
  const [showCarousel, setShowCarousel] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);

  const nextSlide = () => {
    const nextIndex = slideIndex + 1;
    setSlideIndex(nextIndex);

    if (nextIndex === 1) {
      showExamples();
    }
  }

  const handleActivationComplete = () => {
    setShowActivation(false);
    setShowCarousel(true);
  }

  return (
    <div className="IntroView">
      <div className="content" id="IntroViewHeight">

        <div className={`IntroView__carousel ${!showCarousel ? 'IntroView__carousel--hidden' : ''}`}>
          <div className={`IntroView__slider ${slideIndex > 0 ? 'IntroView__slider--second' : ''}`}>

            <div className="IntroView__slide">
              <h2>Imagine if activating your card was that simple.</h2>
              <p>Let us show you how to explore opportunities of integrating NFC for seamless experiences.</p>

              <button className="button button-primary" onClick={nextSlide}>
                Next
              </button>
            </div>

            <div className="IntroView__slide">
              <h3>INNOVATING WITH STARLING</h3>
              <h2>It all starts with&nbsp;a&nbsp;tap…</h2>
              <p>Explore how we could integrate creative technology into your products and services to build seamless experiences for your consumers.</p>

              <button className="button button-explore" onClick={exploreNow}>
                Explore now
                <div className="arrow">
                  <img width="30" src={arrow} alt="arrow" />
                </div>
              </button>
            </div>

          </div>
        </div>

        {
          showActivation &&
          <CardActivation onComplete={handleActivationComplete} />
        }

      </div>

      <div className="bg">
        <div className="bg-mask"></div>
      </div>
    </div>
);
}

export default IntroView;
