import React, {useState, useEffect} from 'react'
// import Loading from './components/loading'
// import SplashView from './views/splashView'
import IntroView from './views/introView'
import './App.css'
import Examples from './components/examples'
import Header from './components/header'

import smoothscroll from 'smoothscroll-polyfill';


const App = () => {
  const [HasScrolled, setHasScrolled] = useState(false);
  const [RenderExamples, setRenderExamples] = useState(false);
  const [SolidHeader, setSolidHeader] = useState(false);

  smoothscroll.polyfill();

  useEffect(() => {
    window.addEventListener('scroll', handleOnScroll)
    return () => {
      window.removeEventListener('scroll', handleOnScroll);
    };
  });

  const handleOnScroll = () => {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop

    // Scroll to top on change
    if ((distanceY > 100) && (HasScrolled === false)) {
      handleScroll()
    }
  }

  const handleScroll = () => {
    const IntroViewHeight = document.getElementById('IntroViewHeight').clientHeight;
    setHasScrolled(true);
    setSolidHeader(true);
    console.log('IntroViewHeight', IntroViewHeight);
    window.scrollTo({top: (IntroViewHeight - 65), behavior: 'smooth'});
  }

  const handleShowExamples = () => {
    setRenderExamples(true);
  }


  return (
    <div className="App">
      <Header shouldMakeHeaderSolid={SolidHeader} />
      <IntroView showExamples={handleShowExamples} exploreNow={handleScroll}/>
      {
        RenderExamples && 
        <Examples shouldRender={RenderExamples} />
      }
    </div>
  );
}

export default App;
