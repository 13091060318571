import React, {useEffect, useState, useRef} from 'react';
import '../lib/scss/card-activation.scss';

const CardActivation = (props) => {

  const [showEntry, setShowEntry] = useState(false);
  const [showExit, setShowExit] = useState(false);
  const tickIcon = useRef(null);

  useEffect(() => {
    const entryTimeout = setTimeout(() => {
      setShowEntry(true);

      setTimeout( () => {
        tickIcon.current.play();
      }, 700);
    }, 500);

    return( () => {
      clearTimeout(entryTimeout);
    })
  }, []);

  const handleClick = () => {
    setShowExit(true);
    setTimeout(() => props.onComplete(), 1000);
  }

  return (
    <div className={`CardActivation ${showEntry ? 'CardActivation--entry' : ''} ${showExit ? 'CardActivation--exit' : ''}`}>
      <div className="CardActivation__modal">
        
        <lottie-player
          class="CardActivation__bg CardActivation__bg--full"
          autoplay
          loop
          src={process.env.PUBLIC_URL + '/assets/lottie-birds.json'}>
        </lottie-player>

        <div className="CardActivation__content">

          <div className="CardActivation__card"></div>
          <div className="CardActivation__message">
            <lottie-player
              class="CardActivation__icon"
              ref={tickIcon}
              src={process.env.PUBLIC_URL + '/assets/lottie-tick-black.json'}>
            </lottie-player>
            <p>Great, your card is<br/>now activated.</p>
          </div>
          <div className="CardActivation__button">
            <button onClick={handleClick} className="button-secondary">Next</button>
          </div>

        </div>
      </div>
    </div>
  );
}

export default CardActivation;