import React from 'react';
import Footer from './footer'
import '../lib/scss/examples.scss'
import tap_gift from '../lib/assets/tap_gift.png'
import iott from '../lib/assets/iott.png'
import tap_tote from '../lib/assets/tap_tote.png'
import tap_faq from '../lib/assets/tap_faq.png'
import tap_card from '../lib/assets/tap_card.png'


const Examples = ({shouldRender}) => {
    if (shouldRender){
      return (
        <div className="exampleContainer">
          <div className="examples">
            <div className="exampleItem">
              <h2>A new way to activate</h2>
              <img src={tap_card} alt="icon" />
              <p>Enhance the customer experience and remove friction points in the banking journey. From card activation through to tap-to-chat.</p>
            </div>
            <div className="exampleItem">
              <h2>Bring your products to life</h2>
              <img src={tap_tote} alt="icon" />
              <p>Turn your physical touch-points into digital channels, activating new and existing content to engage and inspire consumers.</p>
            </div>
            <div className="exampleItem">
              <h2>Tap to discover</h2>
              <img src={tap_faq} alt="icon" />
              <p>Unlock new content and services to make your experience as accessible and rewarding as possible.</p>
            </div>
            <div className="exampleItem">
              <h2>Tap for offers</h2>
              <img src={tap_gift} alt="icon" />
              <p>Deliver new forms of marketing content and offers, that leverage NFC and drive new forms of engagement with consumers.</p>
            </div>
            <div className="exampleItem">
              <h2>Understand your consumers</h2>
              <img src={iott} alt="icon" />
              <p>Use SharpEnd’s proprietary io.tt platform to capture analytics and data on when, where and how your products and services are engaged with in the real world.</p>
            </div>
          </div>
          <Footer />
        </div>
      );
    } else {
      return(
        <span></span>
      );
    }
}

export default Examples;
